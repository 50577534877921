<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <div class="section-body">
      <h2 class="section-title">{{ name }}</h2>
      <div class="row">
        <div class="col-12">
          <button
            class="btn btn-secondary mr-3"
            @click="getData((page = 1), (status = ''))"
          >
            ALL
          </button>
          <button
            class="btn btn-primary mr-3"
            @click="getData((page = 1), (status = 'login'))"
          >
            <i class="fas fa-sign-in-alt"></i> LOGIN
          </button>
          <button
            class="btn btn-success mr-3"
            @click="getData((page = 1), (status = 'created'))"
          >
            <i class="fa fa-plus"></i> CREATED
          </button>
          <button
            class="btn btn-warning mr-3"
            @click="getData((page = 1), (status = 'updated'))"
          >
            <i class="fas fa-edit"></i> UPDATED
          </button>
          <button
            class="btn btn-danger mr-3"
            @click="getData((page = 1), (status = 'deleted'))"
          >
            <i class="fa fa-trash"></i> DELETED
          </button>
          <div class="form-row">
            <div class="col">
              <label>Start At</label>
              <input type="date" class="form-control" v-model="form.start_at" />
            </div>
            <div class="col">
              <label>End At</label>
              <input type="date" class="form-control" v-model="form.end_at" />
            </div>
            <div class="col">
              <label>Venue</label>
              <select class="form-control" v-model="form.venue_id">
                <option value>--SELECT VENUE--</option>
                <option
                  v-for="venue in venues"
                  :key="venue.id"
                  :value="venue.value"
                  >{{ venue.text }}</option
                >
              </select>
            </div>
            <div class="col">
              <label>Activity Type</label>
              <select class="form-control" v-model="form.event">
                <option value>--SELECT ACTIVITY TYPE--</option>
                <option v-for="event in events" :value="event" :key="event">{{
                  event
                }}</option>
              </select>
            </div>
          </div>
          <div class="text-right">
            <button class="btn btn-success" @click="exportData">
              Export PDF
            </button>
          </div>

          <div class="activities my-3">
            <div class="row">
              <v-pagination
                v-model="page"
                :length="activityLog.last_page"
                total-visible="7"
                class="mb-3 col-12"
                @input="getData"
              ></v-pagination>
              <div class="col-12">
                <v-progress-circular
                  v-if="loading"
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                <div
                  v-else
                  class="activity"
                  v-for="(log, index) in activityLog.data"
                  :key="index"
                >
                  <div
                    class="activity-icon text-white shadow-primary"
                    :class="{
                      'bg-primary': log.description == 'login',
                      'bg-success': log.description == 'created',
                      'bg-warning': log.description == 'updated',
                      'bg-danger': log.description == 'deleted',
                    }"
                  >
                    <i
                      :class="{
                        'fas fa-sign-in-alt': log.description == 'login',
                        'fa fa-plus': log.description == 'created',
                        'fa fa-trash': log.description == 'deleted',
                        'fas fa-edit': log.description == 'updated',
                      }"
                    ></i>
                  </div>
                  <div class="activity-detail">
                    <div class="mb-2">
                      <span class="text-job text-primary">{{
                        log.created_at
                      }}</span>
                      <span
                        class="bullet"
                        v-if="log.description != null"
                      ></span>
                      <a class="text-job">{{ log.description }}</a>
                      <div class="float-right dropdown">
                        <a href="#" data-toggle="dropdown">
                          <i class="fas fa-ellipsis-h"></i>
                        </a>
                        <div class="dropdown-menu">
                          <div class="dropdown-title">Options</div>
                          <router-link
                            :to="{
                              name: 'LogActivityShow',
                              params: { id: log.id },
                            }"
                            class="dropdown-item has-icon"
                          >
                            <i class=" fas fa-eye"></i> View
                          </router-link>
                        </div>
                      </div>
                    </div>
                    <p>
                      {{ log.description }} {{ log.subject_type }}. By
                      {{ log.causer_name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { VenueRepository } from "../../repositories/RepositoryFactory";

export default {
  name: "LogActivity",
  data() {
    return {
      name: "Log Activity",
      activityLog: [],
      loading: false,
      events: ["login", "created", "updated", "deleted"],
      venues: [],
      form: {
        start_at: "",
        end_at: "",
        venue_id: "",
        event: "",
      },
      status: "",
      page: "",
    };
  },
  mounted() {
    this.getData();
    VenueRepository.getForSelect().then((res) => {
      this.venues = res.data.data;
    });
  },
  methods: {
    getData() {
      this.loading = true;
      axios
        .get(this.$store.state.api + "activity_log", {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            page: this.page,
            search: this.status,
          },
        })
        .then((result) => {
          this.loading = false;
          this.activityLog = result.data.data;
          this.page = result.data.data.current_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    exportData() {
      axios({
        url: this.$store.state.api + "activity_log/download",
        method: "GET",
        responseType: "blob",
        Authorization: this.$store.state.token,
        params: this.form,
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "log-activity.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "User Account",
          value: "causer_name",
        },
        {
          text: "Amount",
          value: "nominal",
        },
      ];
    },
  },
};
</script>
